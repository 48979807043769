import { toFixed } from '../utils/util.js'
import { unique } from '../utils/unique.js'

const MAX_HOUR_IN_DAY = 24

/**
 * @function 检查输入的数据是否为合法的（一天内）小时数
 * @param {Number} value 待检查的输入数据
 */
const isValidHourInDay = (value, maxHour) => {
  if (!(typeof maxHour === 'number' && maxHour >= 0)) {
    maxHour = MAX_HOUR_IN_DAY
  }

  return !!(typeof value === 'number' && value >= 0 && value <= maxHour)
}

/**
 * @function 格式化七天的小时数组
 *           检查每一项数据的有效性
 *           转换后的数据去掉多余的小数位
 * @param {Array} input [1, '', 3, 0, 0, 0, 7]
 */
const formateHours = (input, maxHour, decimal = 2, convert = true) => {
  const _hours = [0, 0, 0, 0, 0, 0, 0]
  let converted = {}

  if (input && input.length >= 7) {
    for (let i = 0; i < 7; i++) {
      if (isValidHourInDay(input[i], maxHour)) {
        _hours[i] = toFixed(input[i], decimal)
      } else if (input[i] === '') {
        _hours[i] = ''
      }
    }
  }

  if (convert) {
    converted = _hours.map(h => {
      return { value: h, origin: h, key: unique('hour-') }
    })
  }

  return {
    hours: _hours,
    converted
  }
}

/**
 * @function 两个七天小时数组的每项相加
 * @param {Array} hours1
 * @param {Array} hours2
 */
const addHours = (hours1, hours2) => {
  const _hours = []

  for (let i = 0; i < 7; i++) {
    let v1 = Number(hours1[i])
    let v2 = Number(hours2[i])
    v1 = v1 || 0
    v2 = v2 || 0
    _hours.push(v1 + v2)
  }

  return _hours
}

const getHoursByWeek = (timeList) => {
  if (timeList) {
    return [
      timeList.monday,
      timeList.tuesday,
      timeList.wednesday,
      timeList.thursday,
      timeList.friday,
      timeList.saturday,
      timeList.sunday
    ]
  }
  return [0, 0, 0, 0, 0, 0, 0]
}

export {
  isValidHourInDay,
  formateHours,
  addHours,
  getHoursByWeek
}
