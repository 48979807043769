<template>
  <div class="working-hours" v-loading="loading">
  <!-- 标题 -->
    <div class="header flex-c-c">{{lang.WorkingHours.title}}</div>
    <!-- 用户 -->
    <div class="user">
      <span>{{loginInfo.user_info.Name}} </span>
      <span>{{lang.WorkingHours.hello}}, {{lang.WorkingHours.writeHours}}</span>
    </div>
    <div class="outside">
      <div class="insert">
        <div class="right-auto project-insert"  @click="openInsertDialog">
          <i class="el-icon-circle-plus-outline"></i>
        </div>
      </div>
      <!-- 主体 -->
      <div class="body">
        <!-- 表头日期 -->
        <div class="table-header flex-c">
          <div class="table-cell first-cell flex-c">
            <div class="table-check-all flex-c-c" :class="{ 'is-checked': !!allChecked }" @click.stop="onCheckAllProjects()">
              <i class="iconfont iconcheck" v-show="allChecked === true"></i>
              <i class="iconfont iconminus" v-show="allChecked === 'indeterminate'"></i>
            </div>
            <span>{{lang.WorkingHours.listOfParticipatingProjects}}</span>
          </div>
          <div class="table-cell flex-c" v-for="(d, column) in table.date" :key="column">
            <div>{{d}}</div>
          </div>
        </div>
        <!-- 项目工时汇总 -->
        <div class="project-total flex-c">
          <div class="table-cell first-cell flex-c">
            <span>{{lang.WorkingHours.projectWorkingHours}}</span>
            <span class="project-total-count flex-c">{{table.projectTotalCount.toFixed(1)}}</span>
            <div class="button-mini is-warn" @click="onClearAll">{{lang.WorkingHours.clearAll}}</div>
          </div>
          <template v-for="(pt, column) in table.projectTotal">
            <div :key="column" class="table-cell flex-c">
              <input-number v-model="pt.value" :min="0" :max="24"
                @change="(value) => { onProjectTotalInput(value, column) }">
              </input-number>
              <div class="button-mini cell-avg" @click="onAverageProject(column)">{{lang.WorkingHours.average}}</div>
            </div>
          </template>
        </div>
        <!-- 项目工时详情 -->
        <div class="project-detail">
          <template v-for="(pj, row) in table.projects">
            <div :key="row" class="project-item flex-c" v-if="pj && pj.activity_name && pj.hours && pj.hours.length === 7">
              <div class="table-cell first-cell flex-c">
                <div class="table-check-row flex-c-c" :class="{ 'is-checked': pj.checked }" @click.stop="onCheckProject(pj, row)">
                  <i class="iconfont iconcheck" v-show="pj.checked"></i>
                </div>
                <span class="activity-name" :title="pj.activity_name">{{pj.activity_name}}</span>
                <span class="right-auto project-remove" @click="onRemoveItem(pj,row)">
                  <i class="el-icon-remove-outline"></i>
                </span>
              </div>
              <template v-for="(h, column) in pj.hours">
                <div :key="column" class="table-cell project-hours flex-c">
                  <input-number v-model="h.value" :min="0" :max="24"
                    @change="(value) => { onProjectInput(value, row, column) }">
                  </input-number>
                </div>
              </template>
            </div>
          </template>
          <div class="project-item " v-if="table.projects === null || table.projects.length ===0">
            <div class="table-cell  flex-c">
              <span>Please Select Your Activities</span>
            </div>
          </div>
        </div>
        <!-- 其他工作工时 -->
        <div class="other-total flex-c">
          <div class="table-cell first-cell flex-c">
            <span class="cell-title">{{lang.WorkingHours.otherTotal}}</span>
            <div class="button-mini" @click="onAutoFill">{{lang.WorkingHours.autoFill}}</div>
          </div>
          <template v-for="(ot, column) in table.otherTotal">
            <div :key="column" class="table-cell flex-c">
              {{ot.value}}
            </div>
          </template>
        </div>
        <!-- 请假 -->
        <div class="ask-leave flex-c">
          <div class="table-cell first-cell flex-c">
            <span class="cell-title">{{lang.WorkingHours.askForLeave}}</span>
          </div>
          <template v-for="(asl, column) in table.askForLeave">
            <div :key="column" class="table-cell flex-c">
              <input-number v-model="asl.value" :min="0" :max="24"
                @change="(value) => { onOtherWorkInput(value, column, 0) }">
              </input-number>
            </div>
          </template>
        </div>
        <!-- 职能管理工作 -->
        <div class="management flex-c">
          <div class="table-cell first-cell flex-c">
            <span class="cell-title">{{lang.WorkingHours.management}}</span>
          </div>
          <template v-for="(m, column) in table.management">
            <div :key="column" class="table-cell flex-c">
              <input-number v-model="m.value" :min="0" :max="24"
                @change="(value) => { onOtherWorkInput(value, column, 1) }">
              </input-number>
            </div>
          </template>
        </div>
        <!-- 本周总工时 -->
        <div class="week-total flex-c">
          <div class="table-cell first-cell flex-c">
            <span class="cell-title">{{lang.WorkingHours.weekTotal}}</span>
            <span class="project-total-count flex-c">{{table.totalCount}}</span>
          </div>
          <template v-for="(item, column) in table.total">
            <div :key="column" class="table-cell flex-c">
              <div v-if="item.value < 8 || item.value > 24 " class="redColor">{{item.value}}</div>
              <div v-else>{{item.value}} &radic;</div>
            </div>
          </template>
        </div>
        <div class="message redColor" ref="message"></div>
      </div>
    </div>
    <!-- 底部操作区 -->
    <div class="footer flex-c-e">
      <el-button @click="onSubmit" :disabled="isDisabled" size="mini" type="primary">{{lang.WorkingHours.submit}}</el-button>
    </div>
    <el-dialog
      :title="lang.Dialog.title"
      :visible.sync="dialogVisible"
      :width="width + 'px'"
      :before-close="clearCheck"
      class="dialog">
      <div class="dialogDiv">
        <div class="table-header flex-c">
          <div class="table-cell first-cell flex-c">
            <div class="table-check-all flex-c-c" :class="{ 'is-checked': !!dialogAllChecked }" @click.stop="onCheckAll()">
              <i class="iconfont iconcheck" v-show="dialogAllChecked === true"></i>
              <i class="iconfont iconminus" v-show="dialogAllChecked === 'indeterminate'"></i>
            </div>
            <span>{{lang.Dialog.listOfActivity}}</span>
          </div>
          <div class="table-cell flex-c">
            <span>{{lang.Dialog.subordinateDepartment}}</span>
          </div>
          <div class="table-cell flex-c">
            <span>{{lang.Dialog.projectStatus}}</span>
          </div>
        </div>
        <div class="project-detail">
          <template v-for="(pj, row) in source.projectList">
            <div :key="row" class="project-item flex-c">
              <div class="table-cell  first-cell flex-c">
                <div class="table-check-row flex-c-c" :class="{ 'is-checked': pj.checked }" @click.stop="onCheckDialogProject(pj, row)">
                  <i class="iconfont iconcheck" v-show="pj.checked"></i>
                </div>
                <span>{{pj.activity_name}}</span>
              </div>
              <div class="table-cell  flex-c">
                <span>{{pj.sub_department}}</span>
              </div>
              <div class="table-cell  flex-c">
                <span>{{pj.status}}</span>
              </div>
            </div>
          </template>
          <div class="project-item " v-if="source.projectList === null || source.projectList.length === 0">
            <div class="table-cell  flex-c">
              <span>No data now~</span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clearCheck">Cancel</el-button>
        <el-button type="primary" @click="onInsertProject">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from '../api/api'
import debounce from 'lodash/debounce'
import { toFixed } from '@/utils/util.js'
import { formateHours, addHours, getHoursByWeek } from '@/models/hours.js'
import { unique } from '@/utils/unique.js'
import { sumArray } from '@/utils/array.js'
import { Base64 } from 'js-base64'
import cn from '@/locales/zh_CN'
import InputNumber from '@/components/InputNumber.vue'
const sourceList = {
  askForLeave: {
    id: 'BMA08.a16951',
    name: '休假'
  },
  management: {
    id: 'BMA08.dcbee6',
    name: '本部门职能管理工作'
  }
}
export default {
  name: 'WorkingHours',
  data () {
    return {
      api: null,
      lang: cn,
      username: '小明',
      loginInfo: null,
      width: 800,
      source: {
        askForLeave: [0, 0, 0, 0, 0, 0, 0],
        management: [0, 0, 0, 0, 0, 0, 0],
        projects: [],
        projectList: []
      },
      table: {
        date: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        projectTotal: [],
        projectTotalCount: 0,
        projects: [],
        otherTotal: [],
        askForLeave: [],
        management: [],
        total: [],
        totalCount: 0
      },
      activityiInfo: [],
      firstTable: null,
      allChecked: false,
      dialogAllChecked: false,
      isDisabled: false,
      dialogVisible: false,
      loading: false
    }
  },
  created () {
    try {
      let hash
      location.search ? hash = decodeURIComponent(location.search) : hash = decodeURIComponent(location.hash)
      const sign = hash.substring(hash.indexOf('=') + 1, hash.length)
      console.log(sign)
      this.loginInfo = JSON.parse(Base64.decode(sign.substring(6, sign.length)))
      console.log(this.loginInfo)
      this.api = new API(sign)
      this.getData()
    } catch (error) {
      this.$message.error('初始化信息失败')
      console.log(error)
    }
  },
  mounted () {
    this.onProjectTotalInput = debounce((...args) => {
      this.handleProjectTotalInput(...args)
    }, 300)

    this.onProjectInput = debounce((...args) => {
      this.handleProjectInput(...args)
    }, 300)

    this.onOtherWorkInput = debounce((...args) => {
      this.handleOtherWorkInput(...args)
    }, 300)
  },
  beforeDestroy () {
    this.onProjectTotalInput = null
  },
  methods: {
    getData () {
      this.loading = true
      this.api
        .getWorkingHours()
        .then(res => {
          if (res && res.data && res.data.code !== 200) return this.$message.error('获取数据失败')

          if (res.data && res.data.data && res.data.data.work_times && res.data.data.work_times.activities) {
            const timeList = res.data.data.work_times.activities
            this.source.projects = this.getTimeList(timeList, 'project', '')
            this.source.askForLeave = this.getTimeList(timeList, '', sourceList.askForLeave.id)
            this.source.management = this.getTimeList(timeList, '', sourceList.management.id)
          }

          if (res.data && res.data.data && res.data.data.activity_infos) {
            this.activityiInfo = JSON.parse(JSON.stringify(res.data.data.activity_infos))
            if (res.data.data.work_times.activities) {
              const projectList = this.activityiInfo.filter(item => {
                return !res.data.data.work_times.activities.map(v => v.activity_id).includes(item.activity_id)
              })
              this.source.projectList = projectList.map(v => {
                return { ...v, checked: false }
              })
            } else {
              this.source.projectList = this.activityiInfo.filter(item => item.activity_id !== sourceList.askForLeave.id && item.activity_id !== sourceList.management.id)
                .map(item => { return { ...item, checked: false } })
            }
          }
          this.init()
          console.log('this.source', this.source)
          this.loading = false
        })
        .catch(error => {
          this.$message.error('获取数据失败')
          console.error(error)
        })
    },
    /**
     * @function 组件数据初始化
     */
    async init () {
      this.initTable(this.source)
    },
    /**
     * @function 初始化表格数据
     * @param {Object} source 原始数据
     */
    initTable (source) {
      let projectTotalArr = [0, 0, 0, 0, 0, 0, 0]
      let otherTotalArr = [0, 0, 0, 0, 0, 0, 0]
      let askForLeave = []
      let management = []
      const projects = []
      if (source && source.projects && source.projects.length > 0) {
        for (let i = 0; i < source.projects.length; i++) {
          const project = source.projects[i]
          if (!(project && project.activity_name && project.activity_id)) continue
          const { hours, converted } = formateHours(project.hours)
          projectTotalArr = addHours(projectTotalArr, hours)
          projects.push({
            key: unique('project-'),
            activity_name: project.activity_name,
            activity_id: project.activity_id,
            checked: false,
            hours: converted
          })
        }
      }

      if (source && source.askForLeave && source.askForLeave.length > 0) {
        askForLeave = formateHours(source.askForLeave).converted
        otherTotalArr = addHours(otherTotalArr, source.askForLeave)
      }
      if (source && source.management && source.management.length > 0) {
        management = formateHours(source.management).converted
        otherTotalArr = addHours(otherTotalArr, source.management)
      }
      console.log('projectTotalArr', projectTotalArr, 'otherTotalArr', otherTotalArr)
      this.table.projectTotal = formateHours(
        projectTotalArr,
        Number.MAX_SAFE_INTEGER
      ).converted
      this.table.projectTotalCount = sumArray(projectTotalArr)
      this.table.projects = projects
      this.table.askForLeave = askForLeave
      this.table.management = management
      this.table.otherTotal = formateHours(
        otherTotalArr,
        Number.MAX_SAFE_INTEGER
      ).converted
      setTimeout(() => {
        this.updataWeekTotal()
      }, 10)

      console.log('this.table', this.table)
    },
    /**
     * @function 处理单日单个项目工时的修改操作
     * @param {String} value 修改后的值
     * @param {Number} row 行索引（对应单个项目）
     * @param {Number} column 列索引（对应周一到周日）
     */
    handleProjectInput (value, row, column) {
      this.updateProjectTotalItem(column)
      setTimeout(() => {
        this.updateProjectTotalCount()
        this.updataWeekTotal()
      }, 20)
    },
    /**
     * @function 处理单日项目总工时的修改操作
     * @param {String} value 修改后的值
     * @param {Number} column 列索引
     */
    handleProjectTotalInput (value, column) {
      this.updateProjectTotalCount()
      const projectTotalInDay = this.getProjectTotalInDay(column)
      if (projectTotalInDay !== value) {
        this.getMessageDetail(this.lang.Message.error)
        return false
      }
      setTimeout(() => {
        this.updataWeekTotal()
      }, 10)
    },
    handleOtherWorkInput (value, column, type) {
      this.updataOtherTotal()
      setTimeout(() => {
        this.updataWeekTotal()
      }, 10)
    },
    /**
     * @function 更新单日项目总工时
     * @param {Number} column 列索引（对应周一到周日）
     */
    updateProjectTotalItem (column) {
      const projectTotalInDay = this.getProjectTotalInDay(column)
      this.table.projectTotal[column].value = projectTotalInDay
    },

    /**
     * @function 展示总工时校验
     * @param {Number} column 列索引（对应周一到周日）
     */
    checkSumIsEqual (column, value) {
      const projectTotalInDay = this.getProjectTotalInDay(column)
      if (projectTotalInDay !== value) {
        this.$refs.message.innerHTML = this.lang.Message.error
        this.$refs.message.style.display = 'block'
        this.isDisabled = true
        return false
      }
    },
    /**
     * @function 获取每天工作总工时
     * @param {Number} column 列索引（对应周一到周日）
     */
    getProjectTotalInDay (column) {
      let projectTotalInDay = 0
      if (!(column >= 0 && column <= 6)) return

      if (this.table && this.table.projects && this.table.projectTotal) {
        const projects = this.table.projects
        projects.forEach(pj => {
          if (pj && pj.hours && pj.hours[column]) {
            let hour = Number(pj.hours[column].value)
            hour = hour || 0
            projectTotalInDay += hour
          }
        })
      }
      return projectTotalInDay
    },
    /**
     * @function 更新项目汇总工时
     */
    updateProjectTotalCount () {
      if (this.table && this.table.projectTotal) {
        const projectTotalArr = this.table.projectTotal.map(pt => pt.value)
        this.table.projectTotalCount = sumArray(projectTotalArr)
      }
    },
    /**
     * @function 更新其他工作工时
     */
    updataOtherTotal () {
      if (this.table && this.table.askForLeave && this.table.management) {
        const otherTotalArr = []

        for (let i = 0; i < 7; i++) {
          let askForLeaveItem = this.table.askForLeave[i].value
          askForLeaveItem = Number(askForLeaveItem) || 0
          let managementItem = this.table.management[i].value
          managementItem = Number(managementItem) || 0

          otherTotalArr.push(askForLeaveItem + managementItem)
        }
        this.table.otherTotalCount = sumArray(otherTotalArr)
        this.table.otherTotal = formateHours(
          otherTotalArr,
          Number.MAX_SAFE_INTEGER
        ).converted
      }
    },
    /**
     * @function 更新本周总工时
     */
    updataWeekTotal () {
      if (this.table && this.table.projectTotal && this.table.otherTotal) {
        const totalArr = []
        this.$refs.message.style.display = 'none'
        this.isDisabled = false
        for (let i = 0; i < 7; i++) {
          let projectTotalItem = this.table.projectTotal[i].value
          projectTotalItem = Number(projectTotalItem) || 0
          let otherTotalItem = this.table.otherTotal[i].value
          otherTotalItem = Number(otherTotalItem) || 0
          totalArr.push(projectTotalItem + otherTotalItem)
        }

        this.table.total = formateHours(
          totalArr,
          Number.MAX_SAFE_INTEGER
        ).converted
        this.table.totalCount = sumArray(totalArr)
        this.table.total.some((item, i) => {
          if (item.value > 24) {
            this.getMessageDetail(this.lang.Message.over24)
            return true
          } else if (item.value < 8 && i < 5) {
            this.getMessageDetail(this.lang.Message.lessThan8)
            return true
          }
        })
      }
    },
    /**
     * @function 数据校验提示
     * @param {String} 错误信息提示
     */
    getMessageDetail (message) {
      this.$refs.message.innerHTML = message
      this.$refs.message.style.display = 'block'
      this.isDisabled = true
    },
    /**
     * @function 获取选中的项目数量
     */
    getCheckProjectCount () {
      let checkedCount = 0
      if (this.table && this.table.projects) {
        this.table.projects.forEach(pj => {
          if (pj && pj.checked) checkedCount++
        })
      }
      return checkedCount
    },
    /**
     * @function 全选项目的事件处理
     */
    onCheckAllProjects () {
      this.allChecked = !!(this.allChecked !== true)
      if (this.table && this.table.projects) {
        this.table.projects.forEach(pj => {
          pj.checked = this.allChecked
        })
      }
    },
    onCheckAll () {
      this.dialogAllChecked = !!(this.dialogAllChecked !== true)
      if (this.source.projectList) {
        this.source.projectList.forEach(pj => {
          pj.checked = this.dialogAllChecked
        })
      }
    },
    /**
     * @function 切换单个项目勾选状态的事件处理
     */
    onCheckProject (project, row) {
      project.checked = !project.checked
      const checkedCount = this.getCheckProjectCount()

      if (checkedCount === this.table.projects.length) {
        this.allChecked = true
      } else if (checkedCount > 0) {
        this.allChecked = 'indeterminate'
      } else {
        this.allChecked = false
      }
    },
    /**
     * @function 切换对话框中单个项目勾选状态的事件处理
     */
    onCheckDialogProject (project, row) {
      project.checked = !project.checked
      let checkedCount = 0
      if (this.source.projectList) {
        this.source.projectList.forEach(pj => {
          if (pj && pj.checked) checkedCount++
        })
      }
      if (checkedCount === this.source.projectList.length) {
        this.dialogAllChecked = true
      } else if (checkedCount > 0) {
        this.dialogAllChecked = 'indeterminate'
      } else {
        this.dialogAllChecked = false
      }
    },
    /**
     * @function 平均单日项目工时
     * @param {Number} column 列索引
     */
    onAverageProject (column) {
      const checkedCount = this.getCheckProjectCount()
      // eslint-disable-next-line curly
      if (!(checkedCount > 0))
        return this.$message.warning('No project selected')

      let currentTotal = this.table.projectTotal[column].value
      currentTotal = Number(currentTotal) || 0
      const avgValue = toFixed(currentTotal / checkedCount, 1)
      let lastAvgValue = currentTotal - avgValue * (checkedCount - 1)
      lastAvgValue = toFixed(lastAvgValue, 1) || 0

      this.table.projects.forEach(pj => {
        if (pj.checked) {
          pj.hours[column].value = avgValue
        } else {
          pj.hours[column].value = 0
        }
      })
      for (let i = this.table.projects.length - 1; i >= 0; i--) {
        const pj = this.table.projects[i]
        if (pj.checked) {
          pj.hours[column].value = lastAvgValue
          break
        }
      }
      setTimeout(() => {
        this.updataWeekTotal()
      }, 10)
    },
    /**
     * @function 清空所有的项目工时
     */
    onClearAll () {
      // eslint-disable-next-line curly
      if (!(this.table && this.table.projects && this.table.projectTotal))
        return
      this.table.projects.forEach(pj => {
        if (pj && pj.hours) {
          pj.hours.forEach(h => {
            h.value = ''
          })
        }
      })
      this.table.projectTotal.forEach(pt => {
        pt.value = 0
      })
      this.table.otherTotal.forEach(pt => {
        pt.value = 0
      })
      this.table.askForLeave.forEach(pt => {
        pt.value = ''
      })
      this.table.management.forEach(pt => {
        pt.value = ''
      })
      this.table.projectTotalCount = 0
      setTimeout(() => {
        this.updataWeekTotal()
      }, 20)
    },
    /**
     * @function 自动填充其他工作工时
     */
    onAutoFill () {
      if (!(this.table && this.table.otherTotal && this.table.projectTotal)) {
        return
      }
      if (this.onCheckItemProjectIsNull()) {
        // this.getMessageDetail(this.lang.Message.isEqual)
        return
      }
      for (let i = 0; i < 5; i++) {
        let projectTotalItemValue = this.table.projectTotal[i].value
        projectTotalItemValue = Number(projectTotalItemValue) || 0
        console.log(this.table)
        const otherTotalItem = this.table.otherTotal[i]
        const askForLeaveItem = this.table.askForLeave[i]
        const managementItem = this.table.management[i]

        let filledValue = 0

        if (projectTotalItemValue < 8) {
          filledValue = toFixed(8 - projectTotalItemValue, 1) || 0
          console.log('filledValue', filledValue)
        }

        otherTotalItem.value = filledValue
        managementItem.value = filledValue
        askForLeaveItem.value = 0
      }

      setTimeout(() => {
        this.updataWeekTotal()
      }, 20)
    },
    /**
     * 校验当每天的总工时不为空时，列表项目是否为空
     */
    onCheckItemProjectIsNull () {
      if (!(this.table && this.table.projects && this.table.projectTotal)) {
        // eslint-disable-next-line no-useless-return
        return
      }
      let falg = false
      for (let i = 0; i < 5; i++) {
        if (Number(this.table.projectTotal[i].value) - Number(this.getProjectTotalInDay(i)) > 0.01) {
          falg = true
        }
      }
      return falg
    },
    handleClose (done) {
      this.dialogVisible = false
      this.dialogAllChecked = false
    },
    /**
     * @function 删除某个项目
     */
    onRemoveItem (pj, row) {
      const list = this.table.projects.splice(row, 1)
      for (let i = 0; i < 7; i++) {
        const a = this.getProjectTotalInDay(i)
        this.table.projectTotal[i].value = a
      }

      const removeItem = this.activityiInfo.filter(v => v.activity_id === list[0].activity_id)[0]
      this.source.projectList.push({ ...removeItem, checked: false })

      this.table.projects.forEach(item => {
        let checkedCount = 0
        if (item.checked) checkedCount++
        if (checkedCount === 0) this.allChecked = false
      })

      setTimeout(() => {
        this.updateProjectTotalCount()
        this.updataWeekTotal()
      }, 10)
    },
    /**
     * @function 打开对话框
     */
    openInsertDialog () {
      this.dialogVisible = true
      this.dialogAllChecked = false
    },
    /**
     * @function 获取对话框中未勾选列表
     */
    getCheckProjectList () {
      const list = []
      this.source.projectList.forEach(pj => {
        if (pj.checked) {
          this.table.projects.push({
            key: unique('project-'),
            activity_name: pj.activity_name,
            activity_id: pj.activity_id,
            checked: false,
            hours: formateHours(['', '', '', '', '', '', '']).converted
          })
        } else {
          list.push(pj)
        }
      })
      this.source.projectList = list
    },
    /**
     * @function 关闭对话框
     */
    onInsertProject () {
      this.getCheckProjectList()
      this.dialogVisible = false
      this.dialogAllChecked = false
    },
    clearCheck () {
      this.source.projectList.forEach(item => { item.checked = false })
      this.dialogVisible = false
    },
    /**
     * @function 提交到服务器
     */
    onSubmit () {
      const Activities = []
      this.table.projects.forEach(item => {
        Activities.push(this.getObject(item.activity_name, item.activity_id, item.hours))
      })

      Activities.push(
        this.getObject(sourceList.askForLeave.name, sourceList.askForLeave.id, this.table.askForLeave))
      Activities.push(
        this.getObject(sourceList.management.name, sourceList.management.id, this.table.management))

      if (Activities) {
        this.api.sendWorkingHourus({
          Activities
        })
          .then(res => {
            if (res && res.data && res.data.code !== 200) return this.$message.error('提交失败')
            this.getData()
            if (this.allChecked) this.allChecked = false
            this.$message.success('提交成功')
          })
          .catch(error => {
            this.$message.error('提交失败')
            console.error(error)
          })
      }
    },
    /**
     * @function 格式化对象
     */
    getObject (name, id, item) {
      return {
        activity_name: name,
        activity_id: id,
        monday: Number(item[0].value) || 0,
        tuesday: Number(item[1].value) || 0,
        wednesday: Number(item[2].value) || 0,
        thursday: Number(item[3].value) || 0,
        friday: Number(item[4].value) || 0,
        saturday: Number(item[5].value) || 0,
        sunday: Number(item[6].value) || 0
      }
    },
    /**
     * @function 格式化初始数据
     */
    getTimeList (workTimes, index, id) {
      if (workTimes.length === 0) return

      if (index === 'project') {
        const timeList = []
        workTimes.filter(item => {
          return item.activity_id !== sourceList.askForLeave.id && item.activity_id !== sourceList.management.id
        }).forEach(item => {
          timeList.push({
            activity_name: item.activity_name,
            activity_id: item.activity_id,
            hours: getHoursByWeek(item)
          })
        })
        return timeList
      } else {
        const timeList = workTimes.filter(
          item => item.activity_id === id
        )
        return getHoursByWeek(timeList[0])
      }
    }
  },
  components: { InputNumber }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/global.scss';
@include scrollbar-light;

$body_width: 916px;
$first_column_width: 220px;

$table_header_height: 32px;
$table_row_height: 32px;
$table_font_size: 13px;
$table_font_color: rgba(0,0,0,0.65);
$table_hover_color: rgba(0, 0, 0, 0.05);

.working-hours{
  font-family: $g_font_family;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
  position: relative;
  user-select: auto;
  -webkit-user-select: auto;
}

.working-hours {
  .header{
    box-sizing: border-box;
    margin-top: 40px;
    width: 100%;
    color: $g_theme_color;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0.5;
  }
  .user{
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 40px;
    padding: 0 0 16px 26px;
    width: $body_width;
    color: $g_content_title_color;
    font-size: 15px;
    font-weight: 600;
  }
  .footer{
    box-sizing: border-box;
    margin: 0 auto;
    width: $body_width;
  }

  .body{
    box-sizing: border-box;
    width: $body_width - 16px;
    margin-bottom: 20px;
  }

  .table-header, .project-total, .other-total, .ask-leave, .management, .week-total{
    box-sizing: border-box;
    font-size: $table_font_size;
    color: $table_font_color;
    height: $table_header_height;
    width: 100%;
    border-top: 1px solid $g_border_color;
    border-left: 1px solid $g_border_color;
    border-bottom: 1px solid $g_border_color;
  }
  .project-total {
    border-top: none;
    .project-total-count{
      margin-left: 15px;
      flex: 1;
      height: 100%;
    }
    .cell-avg{
      margin-left: 8px;
    }
    .first-cell{
      padding-left: 30px;
    }
  }

  .project-total{
    background: #597EF7;
    .table-cell{
      color: #FFFFFF;
      /deep/ input{
        color: #FFFFFF;
      }
    }
  }
  .project-detail{
    box-sizing: border-box;
    font-size: $table_font_size;
    font-weight: 400;
    color: $table_font_color;
    width: 100%;
    .project-item{
      box-sizing: border-box;
      height: $table_header_height;
      width: 100%;
      border-left: 1px solid $g_border_color;
      border-bottom: 1px solid $g_border_color;
      background: rgba(89,126,247, 0.1);
    }
  }

  .other-total, .ask-leave, .management, .week-total {
    border-top: none;
    .first-cell{
      padding-left: 30px;
    }
    .cell-title{
      flex: 1;
    }
    .project-total-count{
      margin-right: -8px;
      flex: 1;
      height: 100%;
    }
  }

  .other-total{
    background: #99C769;
    .table-cell{
      color: #FFFFFF;
      /deep/ input{
        color: #FFFFFF;
      }
    }
  }
  .ask-leave, .management{
    background: rgba(153,199,105,0.1);
  }
  .week-total{
    .table-cell{
      color: #F9A825;
      /deep/ input{
        color: #F9A825;
      }
    }
  }

  .table-cell{
    box-sizing: border-box;
    position: relative;
    padding: 0 10px;
    height: 100%;
    font-size: $table_font_size;
    font-weight: 400;
    color: $table_font_color;
    flex: 1;
    -webkit-flex: 1;
    border-right: 1px solid $g_border_color;
    overflow: hidden;
    /deep/ input{
      font-size: $table_font_size;
      font-weight: 400;
      color: $table_font_color;
    }
  }
  .table-cell.first-cell{
    flex: 0 0 $first_column_width;
    width: $first_column_width;
  }
  .project-hours.table-cell{
    padding-right: 10px;
  }
  .table-header .table-cell{
    font-weight: 600;
    color: rgba(0,0,0,0.85);
    /deep/ input{
      font-size: $table_font_size;
      font-weight: 600;
      color: rgba(0,0,0,0.85);
    }
  }

  .table-check-all,
  .table-check-row{
    box-sizing: border-box;
    height: 12px;
    width: 12px;
    font-weight: 400;
    color: rgba(0,0,0,0.85);
    border: 1px solid $g_border_color;
    border-radius: 2px;
    margin-right: 8px;
    cursor: pointer;
    overflow: hidden;
    i.iconfont{
      font-size: 12px;
    }
  }
  .table-check-all:hover,
  .table-check-row:hover{
    border-color: $g_theme_color;
  }
  .table-check-all.is-checked,
  .table-check-row.is-checked{
    border-color: $g_theme_color;
    background-color: $g_theme_color;
    color: #FFFFFF;
  }
}

.button-mini, .button-mini-fill{
  display:inline-block;
  line-height:1;
  white-space:nowrap;
  cursor:pointer;
  background:transparent;
  border:1px solid rgba(255,255,255, 0.6);
  color:#FFFFFF;
  -webkit-appearance:none;
  text-align:center;
  box-sizing:border-box;
  outline:0;
  margin:0;
  transition:.1s;
  font-weight:400;
  -webkit-user-select:none;
  padding:3px 4px;
  font-size:12px;
  border-radius:2px;
}
.button-mini:hover{
  border-color:rgba(255,255,255,1);
  outline:0;
  border-radius:2px;
}
.button-mini:focus,
.button-mini:active{
  border-color:rgba(255,255,255,1);
  outline:0;
  border-radius:2px;
}

.button-mini-fill{
  opacity: 0;
  visibility: hidden;
  color: transparent;
  background: transparent;
  cursor: auto;
}
.redColor {
  color: red !important;
}
.message {
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;

}
.project-insert {
  cursor: pointer;
}
.project-remove {
  cursor: pointer;
}
.right-auto {
  display: inline-block;
  margin-left: auto;
}
.nodata{
  text-align: center;
  padding: 10px 0;
}
.dialog .table-cell.first-cell{
  flex:auto 1 1;
}
.dialog {
  min-width: 400px;
}
.dialogDiv{
  max-height: 518px;
  min-width: 500px;
  overflow-y: scroll;
  overflow-x: scroll;
}
.dialogDiv::-webkit-scrollbar {
  width: 6px;
}
.dialogDiv::-webkit-scrollbar-thumb {
  background-color: #e0e3e5;
  border-radius: 5px;
}
.dialogDiv::-webkit-scrollbar-track {
  background-color: white;
}
.dialogDiv::-webkit-scrollbar-thumb:hover {
  background-color: #e0e3e5;
}
.dialogDiv::-webkit-scrollbar-thumb:active {
  background-color: #e0e3e5;
}
.activity-name{
  width: 11em;
  overflow: hidden;
  /* 显示省略符号来代表被修剪的文本。 */
  text-overflow:ellipsis;
  /* 文本不换行 */
  white-space:nowrap;
}
.outside{
  display: flex;
  margin: 0 auto;
  width: 916px
}

.insert{
  padding:72px 10px 0 0;
}
</style>
