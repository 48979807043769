<template>
  <div class="input-number">
    <input
      class="vue-input"
      ref="input"
      v-bind="$attrs"
      :placeholder="placeholder"
      :type="type"
      @compositionstart="handleCompositionStart"
      @compositionend="handleCompositionEnd"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    >
  </div>
</template>

<script>
import { toFixed } from '@/utils/util.js'

export default {
  name: 'InputNumber',
  data () {
    return {
      isComposing: false,
      focused: false
    }
  },
  props: {
    value: [String, Number],
    type: { type: String, default: 'text' },
    placeholder: { type: String, default: '' },
    max: { type: Number, default: Infinity },
    min: { type: Number, default: -Infinity },
    precision: { type: Number, default: 1 }
  },
  computed: {
    nativeInputValue () {
      return this.value === null || this.value === undefined ? '' : String(this.value)
    }
  },
  watch: {
    nativeInputValue () {
      this.setNativeInputValue()
    }
  },
  mounted () {
    this.setNativeInputValue()
  },
  methods: {
    getInput () {
      if (this.$refs && this.$refs.input) {
        return this.$refs.input
      } else {
        return null
      }
    },
    focus () {
      this.$refs.input.focus()
    },
    blur () {
      this.$refs.input.blur()
    },
    handleCompositionStart () {
      this.isComposing = true
    },
    handleCompositionEnd (event) {
      this.isComposing = false
      this.handleInput(event)
    },
    handleInput (event) {
      if (this.isComposing) return

      const domInputValue = event.target.value
      let newValue = domInputValue === '' ? '' : Number(domInputValue)

      if (typeof newValue === 'number' && !isNaN(newValue)) {
        newValue = toFixed(newValue, this.precision)
        newValue = Math.max(this.min, newValue)
        newValue = Math.min(this.max, newValue)
      } else {
        newValue = ''
      }

      console.log('handleInput', domInputValue, newValue, /^\d+\.$/.test(domInputValue))

      let newValueStr = String(newValue)
      if (/^\d+\.$/.test(domInputValue)) { newValueStr = `${newValueStr}.` }

      this.$emit('input', newValueStr)
      this.$emit('change', newValueStr)
      this.$nextTick(this.setNativeInputValue)
    },
    handleFocus (event) {
      this.focused = true
      this.$emit('focus', event)
    },
    handleBlur (event) {
      this.focused = false
      this.$emit('blur', event)
    },
    handleChange (event) {
    },
    setNativeInputValue () {
      const input = this.$refs.input
      if (!input) return

      if (input.value === this.nativeInputValue) return
      input.value = this.nativeInputValue
    }
  }
}
</script>

<style lang="scss" scoped>
.input-number{
  box-sizing: border-box;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  font-family: inherit;
  overflow: hidden;
}

.vue-input{
  -webkit-appearance: none;
  background-color: transparent;
  background-image: none;
  box-sizing: border-box;
  font-size: inherit;
  padding: 3px 0px 2px 0px;
  width: 100%;
  outline: 0;
  border: none;
  text-align: left;
  overflow: hidden;
  border-bottom: 1px solid transparent;
  transition: border .2s ease;
}
.vue-input:hover{
  outline: 0;
}
.vue-input:focus,
.vue-input:active{
  outline: 0;
  border-bottom: 1px solid #BFBFBF;
}
.vue-input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

</style>
