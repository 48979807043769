<template>
  <div class="home">
    <WorkingHours></WorkingHours>
  </div>
</template>

<script>
import WorkingHours from '@/views/WorkingHours.vue'
import '@/assets/css/flex.css'
import '@/assets/iconfont/iconfont.css'

export default {
  name: 'Home',
  components: { WorkingHours }
}
</script>

<style lang="scss" scoped>

.home{
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  user-select: auto;
  -webkit-user-select: auto;
}
</style>
