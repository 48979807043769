/*
 * @Author: shanqiong.chen
 * @Date: 2021-04-21 17:06:39
 * @LastEditors: your Name
 * @LastEditTime: 2021-06-18 18:25:28
 * @Description:
 */
export default {
  WorkingHours: {
    title: '工时卡',
    hello: '你好',
    writeHours: '请填写本周工时',

    submit: '提交',
    clearAll: '全部清空',
    average: '平均',
    autoFill: '自动补充',

    listOfParticipatingProjects: '我参与的经营活动清单',
    projectWorkingHours: '项目活动工时',
    otherTotal: '其他工作工时',
    askForLeave: '休假',
    management: '职能管理工作',
    weekTotal: '本周总工时'
  },
  Dialog: {
    title: '事业部活动清单',
    listOfActivity: '事业部经营活动清单',
    subordinateDepartment: '所属二级部门',
    projectStatus: '项目状态'
  },
  Message: {
    over24: '每天总工时不能超过24',
    error: '项目工时之和不相等,可点击平均达到平衡',
    lessThan8: '周一至周五总工时必须大于或等于8',
    isEqual: '项目活动工时与项目之和不相等，两者须达平衡方可点击自动补充功能'
  }

}
