
const sumArray = (arr) => {
  let total = 0
  for (let i = 0; i < arr.length; i++) {
    const val = Number(arr[i])
    if (!isNaN(val)) {
      total += val
    }
  }

  return total
}

export {
  sumArray
}
