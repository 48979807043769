// 固定小数位
export const toFixed = (num, s) => {
  const times = Math.pow(10, s)
  const des = num * times + (num >= 0 || -1) * 0.5
  return parseInt(des, 10) / times
}

export const isNum = (num) => {
  return typeof num === 'number' && !isNaN(num)
}
