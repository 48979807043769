/*
 * @Author: shanqiong.chen
 * @Date: 2021-05-12 12:58:32
 * @LastEditors: your Name
 * @LastEditTime: 2021-06-04 14:36:06
 * @Description:
 */
import axios from 'axios'

const baseURL = 'https://chi-cn.aiphis.com/xtaltime.Production/'

class API {
  constructor (sign) {
    this.sign = sign
  }

  getWorkingHours () {
    return axios({
      method: 'get',
      url: baseURL + 'v1/work-time/data',
      params: {
        sign: this.sign
      }
    })
  }

  sendWorkingHourus (data) {
    return axios({
      method: 'post',
      url: baseURL + 'v1/work-time/sub-data',
      params: {
        sign: this.sign
      },
      data
    })
  }
}

export default API
